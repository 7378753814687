import { createRouter, createWebHistory } from 'vue-router'

import Nprogress from 'nprogress';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView/HomeView.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support/SupportView.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/center',
    name: 'center',
    component: () => import('../views/MyCenter/CenterIndex.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/Merchant/MerchantView.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/Community/index.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/mini',
    name: 'mini',
    component: () => import('../views/Mini/index.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/standard',
    name: 'standard',
    component: () => import('../views/Standard/index.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/flagship',
    name: 'flagship',
    component: () => import('../views/FlagShip/index.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/flagship-plus',
    name: 'flagship-plus',
    component: () => import('../views/FlagShipPro/index.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/community-detail/:id',
    name: 'community-detail',
    component: () => import('../views/Community/detail.vue'),
    meta: {
      cache: false,
    },
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/DownLoad/downLoadIndex.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/Merchant/MerchantView.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account/account.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/server',
    name: 'server',
    component: () => import('../views/Server/server.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/community-submit/:type',
    name: 'community-submit',
    component: () => import('../views/Community/submit.vue'),
    meta: {
      cache: true,
    },
    props: true
  },
  {
    path: '/backend',
    name: 'backend',
    component: () => import('../views/Backend/backEnd.vue'),
    meta: {
      cache: true,
    },
  },
  {
    path: '/mini-program/',
    name: 'mini-program',
    component: () => import('../views/miniPro/miniPro.vue'),
    meta: {
      cache: true,
    },
    props: true
  },
]

















const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes,
  transition: 'slide-left'
})







router.beforeEach((to, from, next) => {
  Nprogress.start()//开启进度条
  next();
});



router.afterEach(() => {
  Nprogress.done()//开启进度条
});





export default router
