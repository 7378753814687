import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
import axios from './request/index'
import store from './store/index'
const bus = mitt();
const app = createApp(App)
app.use(store)
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$axios = axios;
app.use(router).mount('#app');
export default bus