import Vuex from 'vuex'
const state = {
    allinfo: JSON.parse((localStorage.getItem("allinfo") ? localStorage.getItem("allinfo") : '{}')),
    userInfo: JSON.parse((localStorage.getItem("userInfo") ? localStorage.getItem("userInfo") : '{}')),

}
const mutations = {
    userInfo(state, data) {
        state.userInfo = data
    },
    setAllinfo(state, data) {
        state.allinfo = data

    },
}
const getters = {
}
export default new Vuex.Store({
    state,
    mutations,
    getters
})
